import React from 'react';

const Reports = () => (
  <div>
    <h1 className="bolder">
      Page under construction
    </h1>
  </div>
);

export default Reports;
