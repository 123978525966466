import React from 'react';
import { NavLink } from 'react-router-dom';
import Loader from '../../pages/Loader';
import './products.css';

const Accessories = ({ products, status, error }) => {
  const NGNaira = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  });

  const accessories = products.filter((item) => item.product_category.name === 'accessory');

  if (accessories.length < 1) {
    return (
      <div>
        <header>

          <h1 className="warning-center"> Please Add some Accessories to your collection</h1>
        </header>
      </div>
    );
  }
  return (

    <>

      {accessories.map((product) => (
        <div key={product.id} className="products-display products-display sm:min-w-48 w-full">
          <div className="prod-img">
            <NavLink to={`/productdetails/${product.id}`}>
              <img src={product.photo_urls ? product.photo_urls[0] : product.image} alt={product.name} />
            </NavLink>

          </div>
          <div className="prod-details">
            <h5 className="text-gray-900">
              {product.name.substring(0, 15)}
              ...
            </h5>
            <p>{NGNaira.format(product.price)}</p>
            <NavLink className="btn btn-outline" to={`/productdetails/${product.id}`}>
              Buy
            </NavLink>

          </div>
        </div>

      ))}

    </>
  );
};

export default Accessories;
