import React from 'react';

const Messages = () => (
  <div>
    <h1 className="bolder">
      Page Under construction

    </h1>
  </div>
);

export default Messages;
